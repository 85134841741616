import { handleQueryResolve } from '../utils'

export default function () {
  const attributes = []

  // if(name){
  //     attributes.push({
  //         param: 'name',
  //         type: 'sql.VarChar(50)',
  //         value: '%' + name + '%'
  //     })
  // }
  return this.query(`
    SELECT
        Patient.ID AS PatientID,
        Patient.LastName AS LastName,
        Patient.FirstName AS FirstName,
        PP.Phone AS PhoneNumber
    FROM
    Pharmacy.dbo.Pat as Patient
    INNER JOIN Pharmacy.dbo.Rx on Patient.ID = Rx.PatID
    CROSS APPLY (SELECT TOP 1 PatPhone.Phone FROM Pharmacy.dbo.PatPhone WHERE PatPhone.PatID = Patient.ID) as PP
    WHERE
        Rx.FillDate BETWEEN DATEADD(month,-3,GetDate()) And GetDate()
    AND
        Patient.ID NOT IN (
            SELECT DISTINCT
                Rx.PatID
            FROM Pharmacy.dbo.Rx
            WHERE Rx.Filldate BETWEEN DATEADD(year,-1,GetDate()) And GetDate()
            AND Rx.DIN IN (93899979, 93899988, 93899987)
        )
    GROUP BY
        Patient.ID,
        Patient.LastName,
        Patient.FirstName,
        PP.Phone
    HAVING count(Rx.DIN) > 2
    ORDER BY Patient.LastName, Patient.FirstName;
    `).then(handleQueryResolve)
}
